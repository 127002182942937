export default {
  ACTIVATE_ANJ: Symbol('ACTIVATE_ANJ'),
  DEACTIVATE_ANJ: Symbol('DEACTIVATE_ANJ'),
  WITHDRAW_ANJ: Symbol('WITHDRAW_ANJ'),
  DRAFT_JURY: Symbol('DRAFT_JURY'),
  COMMIT_VOTE: Symbol('COMMIT_VOTE'),
  REVEAL_VOTE: Symbol('REVEAL_VOTE'),
  LEAK_VOTE: Symbol('LEAK_VOTE'),
  APPROVE_FEE_DEPOSIT: Symbol('APPROVE_FEE_DEPOSIT'),
  APPEAL_RULING: Symbol('APPEAL_RULING'),
  CONFIRM_APPEAL: Symbol('CONFIRM_APPEAL'),
  EXECUTE_RULING: Symbol('EXECUTE_RULING'),
  HEARTBEAT: Symbol('HEARTBEAT'),
  SETTLE_REWARD: Symbol('SETTLE_REWARD'),
  SETTLE_APPEAL_DEPOSIT: Symbol('SETTLE_APPEAL_DEPOSIT'),
  CLAIM_REWARDS: Symbol('CLAIM_REWARDS'),
  CLAIM_SUBSCRIPTION_FEES: Symbol('CLAIM_SUBSCRIPTION_FEES'),
}
